<template>
	<div>
		<Header2022 :navbg='true'></Header2022>
		<div class="content">
			<Banner :bannerList="bannerList" height="650px" :showtitle="showtitle"></Banner>
			<div class="main">
				<div class="huxing">
					<div class="page">
						<div class="title">小院生活</div>
						<div class="desc">
							小院生活紧跟时代发展步伐，结合城市发展规划及人们对人居舒适度的期待和要求，力求为城市人居发展提供智慧化的住宅解决方案，实现高效率的城市生活和高舒适度、低密度的生活模式相统一。团队经过多年潜心研发，匠心精筑，最终形成了区间面积100-200㎡，前庭后院双花园、容积率趋于1.0的小院式经典户型，可满足多类人群刚需及改善性住房需求，享受静谧惬意的庭院生活。
						</div>

						<div class="imgWarp">
							<div class="item" @click="houseType(105)">
								<img src="../../../assets/images/huxing1.jpg" alt="">
								<div class="area">105㎡</div>
							</div>
							<div class="item" @click="houseType(138)">
								<img src="../../../assets/images/huxing2.jpg" alt="">
								<div class="area">138㎡</div>
							</div>
							<div class="item" @click="houseType(163)">
								<img src="../../../assets/images/huxing3.jpg" alt="">
								<div class="area">163㎡</div>
							</div>
							<div class="item" @click="houseType(210)">
								<img src="../../../assets/images/huxing4.jpg" alt="">
								<div class="area">210㎡</div>
							</div>
						</div>

					</div>
				</div>

				<div class="xiangmu">
					<div class="page">
						<div class="top">
							<img src="../../../assets/images/future-city.jpg" alt="" class="xiangmuImg">
							<div class="miaoshu">
								<div class="title">未来城</div>
								<div class="desc" style="text-align: justify;">
									经开·未来城位于渭南市经济技术开发区田园都市区核心位置，紧邻渭南中学。总占地174亩,总建筑面积12.7万㎡，共计83栋低层高品质庭院住宅。建筑形体设计采用新古典建筑风格,容积率1.2,以140㎡改善型三居室为主,社区配备多层级景观绿地、娱乐健身设施、小院生活馆及社区商业街,满足静谧高品质生活需求。
								</div>
								<el-button class="more" type="primary" round @click="projectClick('futureCity')">更多</el-button>
							</div>
						</div>
						<div class="bottom">
							<div class="item">
								<img src="../../../assets/images/sbw.jpg">
								<div class="title">水半湾</div>
							</div>
							<div class="item">
								<img src="../../../assets/images/ayhf.jpg">
								<div class="title">奥韵华府</div>
							</div>
							<div class="item">
								<img src="../../../assets/images/syf.jpg">
								<div class="title">水一方</div>
							</div>
						</div>
					</div>
				</div>

				<div class="icon">
					<div class="page">
						<div class="warp">
							<div class="item">
								<i class="iconfont icon-safe"></i>
								<div class="title">抗震性最好</div>
								<div class="desc">短肢剪力墙结构</div>
								<div class="desc">现浇结构至少30％</div>
							</div>
							<div class="item">
								<i class="iconfont icon-punch"></i>
								<div class="title">降板同层排水</div>
								<div class="desc">
									洁具位置不受限
								</div>
								<div class="desc">低噪声+舒适高度+维修便捷</div>
							</div>
							<div class="item">
								<i class="iconfont icon-brand"></i>
								<div class="title">热水循环</div>
								<div class="desc">
									多点位同时供应
								</div>
								<div class="desc">舒适，节水，节能</div>
							</div>
							<div class="item">
								<i class="iconfont icon-we"></i>
								<div class="title">断桥铝窗外开窗</div>
								<div class="desc">
									气密性更好

								</div>
								<div class="desc">不占室内空间</div>
							</div>
						</div>
						<div class="warp">
							<div class="item">
								<i class="iconfont icon-square"></i>
								<div class="title">VELUX天窗</div>
								<div class="desc">
									引光入室+增加自然通风

								</div>
								<div class="desc">防水更好，可定制升级</div>
							</div>
							<div class="item">
								<i class="iconfont icon-evaluate"></i>
								<div class="title">新风预留</div>
								<div class="desc">
									净化室内空气

								</div>
								<div class="desc">除菌、消毒、祛湿</div>
							</div>
							<div class="item">
								<i class="iconfont icon-flashlightopen"></i>
								<div class="title">空气源热泵</div>
								<div class="desc">
									温度波动小，无风口

								</div>
								<div class="desc">冬夏一套设备</div>
							</div>
							<div class="item">
								<i class="iconfont icon-shop"></i>
								<div class="title">光伏屋面</div>
								<div class="desc">
									绿色低碳

								</div>
								<div class="desc">节能减耗</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	import Banner from "@/components/banner.vue"
	import utils from '@/common/utils'
	import {
		mapState
	} from 'vuex'
	export default {
		data() {
			return {
				bannerList: [],
				showtitle:false,
			}
		},
		components: {
			Banner,
			Header2022,
			Footer2022,
		},
		computed: {
			...mapState(['ocApi'])
		},
		created() {
			this.getBanner();
		},
		methods: {
			getBanner: function() {
				let that = this;
				that.$axios.get(that.ocApi + 'book/banner', {
						params: {
							'name': 'pc_product_dooryard',
						}
					})
					.then(res => {
						if (res.data.status == 1) {
							that.bannerList = res.data.data.images
						} else {
							that.$message.error(res.data.message);
						}
					})
			},
			houseType: utils.debounce(function(e) {
				this.$router.push({
					name: 'productDooryardDetail',
					params: {
						houseType: e
					}
				});
			}, 500, true),

			projectClick: utils.debounce(function(name) {
				this.$router.push({
					name
				});
			}, 500, true),

		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 1200px;
		margin: 0 auto;
	}

	.huxing {
		background-color: #fff;
		padding: 100px 0;
	}

	.huxing .title {
		font-size: 40px;
		color: #303233;
		text-align: center;
		font-weight: bold;
		line-height: 40px;
		margin-bottom: 59px;
	}

	.huxing .desc {
		color: #303233;
		font-size: 16px;
		text-align: center;
	}

	.huxing .imgWarp {
		width: 100%;
		display: flex;
		margin-top: 60px;
	}

	.huxing .imgWarp .item {
		flex: 1;
		position: relative;
		cursor: pointer;
	}

	.huxing .imgWarp .item .area {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		font-size: 40px;
		color: #ffffff;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.xiangmu {
		background-color: #f6f6f6;
		padding: 100px 0;
	}

	.xiangmu .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.xiangmu .top .xiangmuImg {
		width: 580px;
		height: auto;
	}

	.xiangmu .top .miaoshu {
		margin-left: 100px;
	}

	.xiangmu .top .miaoshu .title {
		font-size: 40px;
		color: #303233;
		font-weight: bold;
	}

	.xiangmu .top .miaoshu .desc {
		font-size: 16px;
		color: #303233;
		line-height: 26px;
		margin-top: 55px;
	}

	.xiangmu .top .miaoshu .more {
		font-size: 14px;
		line-height: 12px;
		padding: 14px 28px;
		border-radius: 100px;
		display: inline-block;
		margin-top: 43px;
	}

	.xiangmu .bottom {
		margin-top: 60px;
		display: flex;
		justify-content: space-between;
	}

	.xiangmu .bottom .item {
		flex: 1;
		margin-right: 30px;
		height: 241px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #000;
		overflow: hidden;
		cursor: pointer;
	}

	.xiangmu .bottom .item:last-of-type {
		margin-right: 0;
	}

	.xiangmu .bottom .item .title {
		font-size: 24px;
		color: #ffffff;
	}

	.icon {
		background-color: #fff;
		padding: 100px 0 118px 0;
	}

	.icon .warp {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;
	}

	.icon .warp:last-of-type {
		margin-bottom: 0;
	}

	.icon .warp .item {
		width: 200px;
		text-align: center;
		color: #303233;
	}

	.icon .warp .item .iconfont {
		font-size: 64px;
		line-height: 64px;
	}

	.icon .warp .item .title {
		font-size: 24px;
		margin-top: 40px;
		margin-bottom: 20px;
	}

	.icon .warp .item .desc {
		font-size: 14px;
		color: #666666;
	}

	.huxing .page .desc {
		text-indent: 2em;
		width: 95%;
		text-align: justify;
		margin: auto;
		line-height: 24px;
	}

	.xiangmu .bottom .item img {
		width: 100%;
		height: auto;
	}

	.xiangmu .bottom .item {
		position: relative;
	}

	.xiangmu .bottom .item .title {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
